// import { ref } from 'vue'
import {Query} from '@directus/types'
import {Logger} from '../../../utils/logger.utils'
import {TypeStaticPageDto, TypeStaticPageEntity} from "./pages.type"

export const useStaticPage = () => {

  const globalSettings: any = inject("globalSettings");
  const {getItems} = useDirectusItems();
  const {getTransItemMulti} = useParseData()
  const {generateLangSwitch} = useGenerateUrl(globalSettings);
  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);
  const getStaticPage = async (languageCode: string, slug: string): Promise<TypeStaticPageDto | any> => {
    try {
      let data: any = await getStaticEntity({
        query: {
          fields: [
            'id',
            'status',
            "translations.languages_code",
            "translations.title",
            "translations.slug",
            'translations.content',
            "translations.seo_details.*",
            "translations.seo_detail.*",
          ],
          filter: {
            _and: [
              {
                translations: {
                  slug: {
                    _eq: slug
                  }
                }
              }
            ]
          }
        }
      })
      if (data) {
        const page: any = {
          slug: getTransItemMulti(data[0], languageCode, 'slug'),
          title: getTransItemMulti(data[0], languageCode, 'title'),
          content: getTransItemMulti(data[0], languageCode, 'content'),
          seo_details: getTransItemMulti(data[0], languageCode, 'seo_details'),
        }
        delete page["translations"];
        page.lang_switch = generateLangSwitch('term_and_condition', languageCode, data[0]);
        return page
      }

      throw Error('Error: getStaticPage')
    } catch (e: any) {
      logger.error('ERR getStaticPage : ', e.message)
      logger.debug(e)
    }
  }

  const getStaticEntity = async (options?: {
    id?: string | number
    query?: Query
  }): Promise<Array<TypeStaticPageEntity> | TypeStaticPageEntity> => {
    let data: any = {
      collection: 'static_pages',
      params: {
        fields: ['*', 'translations.*'],
      }
    }

    if (options?.query) {
      data.params = {
        ...data.params,
        ...options.query
      }
    }
    return getItems(data)
  }

  return {
    getStaticPage,
    logger
  }
}
