<template>
  <section class="container py-20">
    <div class="w-full lg:max-w-211">
      <div class="pb-5 border-b border-bw-08 mb-10">
        <global-breadcrumb :breadcrumb="breadcrumb" class="font-medium"></global-breadcrumb>
      </div>
      <h1 class="text-primary mb-10 uppercase font-[550] md:text-3xl md:leading-44px text-xl leading-2xl">
        {{ dataRender?.title }}
      </h1>
      <div v-if="dataRender?.content" class="post-content-dynamic-page" v-html="dataRender?.content"></div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const {getStaticPage} = useStaticPage()
const {t} = useI18n()
const breadcrumb = ref<Object[]>([
  {
    title: t('HOME'),
    url: useRoute().params.langCode == 'vi-VI' ? '/' : '/en'
  }
])
const dataRender = ref()
await getStaticPage(useRoute().params.langCode, useRoute().params.slug)
    .then((response) => {
      dataRender.value = response
      breadcrumb.value.push({
        title: response?.title,
        url: '/' + response?.slug
      })
    })
    .catch((error) => {
      console.error(error)
    })

// console.log(`output->dataRender.value `, dataRender.value)

useDirectusCollectionSeo('static_page', {
  ...dataRender.value,
  seo_detail: {
    ...dataRender.value?.seo_detail
  }
})
const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = dataRender.value?.lang_switch
})
</script>

<style>
.post-content-dynamic-page {
  @apply flex flex-col gap-3;
}

.post-content-dynamic-page h2 {
  @apply text-2xl leading-8 font-medium text-bw-03;
}

.post-content-dynamic-page h3 {
  @apply text-xl leading-7 font-medium text-bw-03;
}

.post-content-dynamic-page h4 {
  @apply text-lg leading-6 font-medium text-bw-03;
}

.post-content-dynamic-page h5 {
  @apply text-sm leading-6 font-medium text-bw-03;
}

.post-content-dynamic-page ol {
  list-style-type: decimal;
  @apply flex flex-col gap-3 pl-5;
}

.post-content-dynamic-page ul {
  @apply flex flex-col gap-1;
}

.post-content-dynamic-page ul li {
  @apply text-black/60 font-[550] leading-10 text-base !list-disc;
}

.post-content-dynamic-page img {
  @apply rounded-5 w-full object-cover;
}

.post-content-dynamic-page a {
  @apply !text-primary;
}
</style>
